import {
  UserOutlined,
  BankOutlined,
  BranchesOutlined,
  AlertOutlined,
  /* ClockCircleOutlined */ ProfileOutlined,
  IssuesCloseOutlined,
  MobileOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons';

const pagesNavTree = [
  {
    key: 'pages',
    path: `/`,
    title: 'sidenav.pages',
    breadcrumb: false,
    submenu: [
      {
        key: 'pages-users',
        path: `/users`,
        title: 'sidenav.pages.users',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-companies',
        path: `/companies`,
        title: 'sidenav.pages.companies',
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [],
      },
      /* {
        key: 'pages-turns',
        path: `/turns`,
        title: 'sidenav.pages.turns',
        icon: ClockCircleOutlined,
        breadcrumb: false,
        submenu: [],
      }, */
      {
        key: 'pages-routes',
        path: `/routes`,
        title: 'sidenav.pages.routes',
        icon: BranchesOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-messages',
        path: `/messages`,
        title: 'sidenav.pages.messages',
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-sales',
        path: `/sales`,
        title: 'sidenav.pages.sales',
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-uses',
        path: `/app-use`,
        title: 'sidenav.pages.app-use',
        icon: MobileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-issues',
        path: `/issues`,
        title: 'sidenav.pages.issues',
        icon: IssuesCloseOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'pages-transport-event',
        path: `/transport-event`,
        title: 'sidenav.pages.transport-event',
        icon: NodeIndexOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...pagesNavTree];

export default navigationConfig;
