import api from './api';

const sale = {
  getTikets: async function (values) {
    try {
      const { data } = await api.get(`/ticket`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTiketReport: async function (values) {
    try {
      const { data } = await api.get(`/ticket/report`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default sale;
