import io from 'socket.io-client';
import { log } from './common';

/* type VehicleMessageType =
  | { longitude: number; latitude: number; time: string }
  | undefined
  | null */

let socket;

export const initiateSocket = (vehicleId, routeId) => {
  socket = io(process.env.REACT_APP_SOCKET_URL);
  log(`Connecting socket...`);
  if (socket && vehicleId) socket.emit('join', { vehicleId, routeId });
};

export const disconnectSocket = (vehicleId) => {
  log('Disconnecting socket...');
  if (socket) {
    socket.emit('leave', { vehicleId });
    socket.disconnect();
  }
};

export const subscribeToVehicle = (cb) => {
  if (!socket) return true;

  socket.on('message', (values) => {
    log('Websocket event received!');
    return cb(null, values);
  });
};

export const getPosition = (vehicleId) => {
  if (socket) socket.emit('message', { vehicleId });
};
