import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AppLayout from 'layouts/app-layout';
import Login from 'views/Login';
import { PrivateOutlet } from 'components/util-components';

const Home = lazy(() => import('views/Home'));
const UserList = lazy(() => import('views/user/List'));
const UserForm = lazy(() => import('views/user/Form'));
const CompanyList = lazy(() => import('views/company/List'));
const CompanyForm = lazy(() => import('views/company/Form'));
const RouteList = lazy(() => import('views/route/List'));
const RouteForm = lazy(() => import('views/route/Form'));
const RouteAddSpot = lazy(() => import('views/route/AddSpots'));
const MessageList = lazy(() => import('views/message/List'));
const MessageForm = lazy(() => import('views/message/Form'));
const TurnList = lazy(() => import('views/turn/List'));
const TurnForm = lazy(() => import('views/turn/Form'));
const NotificationList = lazy(() => import('views/notification/List'));
const NotificationForm = lazy(() => import('views/notification/Form'));
const SalesReport = lazy(() => import('views/Sales'));
const AppUseReport = lazy(() => import('views/AppUses'));
const IssuesReport = lazy(() => import('views/Help'));
const TransportEventReport = lazy(() => import('views/TransportEvent'));

export const routes = [
  {
    path: '/',
    element: <Navigate to={'/home'} />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'users',
        element: <PrivateOutlet />,
        children: [
          {
            element: <UserList />,
            index: true,
          },
          {
            path: 'create',
            element: <UserForm />,
          },
          {
            path: ':id',
            element: <UserForm />,
          },
        ],
      },
      {
        path: 'companies',
        element: <PrivateOutlet />,
        children: [
          {
            element: <CompanyList />,
            index: true,
          },
          {
            path: 'create',
            element: <CompanyForm />,
          },
          {
            path: ':id',
            element: <CompanyForm />,
          },
        ],
      },
      {
        path: 'routes',
        element: <PrivateOutlet />,
        children: [
          {
            element: <RouteList />,
            index: true,
          },
          {
            path: 'create',
            element: <RouteForm />,
          },
          {
            path: ':id',
            element: <RouteForm />,
          },
          {
            path: ':id/spots',
            element: <RouteAddSpot />,
          },
        ],
      },
      {
        path: 'messages',
        element: <PrivateOutlet />,
        children: [
          {
            element: <MessageList />,
            index: true,
          },
          {
            path: 'create',
            element: <MessageForm />,
          },
          {
            path: ':id',
            element: <MessageForm />,
          },
          {
            path: 'notifications',
            element: <NotificationList />,
          },
          {
            path: 'notification/:id',
            element: <NotificationForm />,
          },
        ],
      },
      {
        path: 'turns',
        element: <PrivateOutlet />,
        children: [
          {
            element: <TurnList />,
            index: true,
          },
          {
            path: 'create',
            element: <TurnForm />,
          },
          {
            path: ':id',
            element: <TurnForm />,
          },
        ],
      },
      {
        path: '/sales',
        element: <SalesReport />,
      },
      {
        path: '/app-use',
        element: <AppUseReport />,
      },
      {
        path: '/issues',
        element: <IssuesReport />,
      },

      {
        path: '/transport-event',
        element: <TransportEventReport />,
      },
      {
        path: '*',
        element: (
          <main style={{ padding: '1rem' }}>
            <p>There's nothing here!</p>
          </main>
        ),
      },
    ],
  },
];

export const AppRouter = () => {
  const element = useRoutes(routes);

  return <>{element}</>;
};
