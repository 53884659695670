import React, {useEffect} from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

const LazyDownloadPDFButton = (props) => {
  const { document, fileName, isDisabled } = props;

  const [instance, updateInstance] = usePDF({ document });

  useEffect(updateInstance, [document]);

  return (
    <Button
      icon={<CloudDownloadOutlined />}
      disabled={isDisabled || instance.loading}
      onClick={() => {
        const blob = instance.blob;
        saveAs(blob, fileName);
      }}
    >
      Bajar reporte
    </Button>
  );
};

export default LazyDownloadPDFButton;
