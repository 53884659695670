import React, { useState, useEffect, useRef } from 'react';
import { Drawer } from 'antd';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import Marker from './Marker';
import RoutingMachine from './RoutingMachine';
import DriftMarker from './DriftMarker';
import SaveRoute from './SaveRoute';
import SpotForm from 'views/route/SpotForm';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY;

const MapView = ({ routeId, waypoints: routeWaypoint, vehicleId }) => {
  const [waypoints, setWaypoints] = useState(routeWaypoint);
  const [waypointIndex, setWaypointIndex] = useState(undefined);
  const [showForm, setShowForm] = useState(false);
  const routingMachineRef = useRef(null);

  useEffect(() => {
    if (routeWaypoint) {
      setWaypoints(routeWaypoint);
    }
  }, [routeWaypoint]);

  useEffect(() => {
    if (routingMachineRef.current) routingMachineRef.current.setWaypoints(waypoints);
  }, [waypoints]);

  const handleShowForm = (index) => {
    setWaypointIndex(index);
    setShowForm(true);
  };

  const handleRoutingMachineRef = (copyWaypoints) => {
    routingMachineRef.current.changes.map((wp, i) => {
      if (wp.latLng != null) {
        copyWaypoints[i].latLng = wp.latLng;
      }
      return wp;
    });
  };

  const handleRemoveWaypoint = (index) => {
    const copyWaypoints = [...waypoints];
    handleRoutingMachineRef(copyWaypoints);
    copyWaypoints.splice(index, 1);
    setWaypoints(copyWaypoints);
    handleAddWaypoint(copyWaypoints);
  };

  const handleAddWaypoint = (values) => {
    setWaypoints(values);
    setShowForm(false);
  };

  return (
    <div className="h-100 w-100">
      <MapContainer doubleClickZoom={false} id="mapId" zoom={12} center={[20.6121228, -100.4802576]}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker waypoints={waypoints} onAddWaypoint={handleAddWaypoint} onRoutingMachineRef={handleRoutingMachineRef} />

        <DriftMarker vehicleId={vehicleId} routeId={routeId}/>

        <RoutingMachine
          waypoints={waypoints}
          ref={routingMachineRef}
          onShowForm={handleShowForm}
          mapApiKey={MAPBOX_KEY}
        />

        <SaveRoute spots={waypoints} routeId={routeId} routingMachineRef={routingMachineRef} />
      </MapContainer>
      <Drawer placement="left" width={350} onClose={() => setShowForm(false)} visible={showForm} destroyOnClose>
        <SpotForm
          id={routeId}
          waypoints={waypoints}
          waypointIndex={waypointIndex}
          onAddWaypoint={handleAddWaypoint}
          onRemoveWaypoint={handleRemoveWaypoint}
        />
      </Drawer>
    </div>
  );
};

export default MapView;
