import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Provider from './providers';
import { AppRouter } from 'routes';

const App = () => {
  return (
    <Provider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <ToastContainer id="toastId" closeButton />
    </Provider>
  );
};

export default App;
