import React, { useState, useEffect } from 'react';
/* import { Tooltip } from 'react-leaflet'; */
import ReactLeafletDriftMarker from 'react-leaflet-drift-marker';
import L from 'leaflet';
import { initiateSocket, getPosition, subscribeToVehicle, disconnectSocket } from 'utils/vehicleSocket';

const vehicleIcon = L.icon({
  iconUrl: '/img/map/vehicle-marker.png',
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: [0, -56],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

const DriftMarker = ({ vehicleId, routeId }) => {
  const [position, setPosition] = useState();

  useEffect(() => {
    if (vehicleId) {
      initiateSocket(vehicleId, routeId);
      getPosition(vehicleId);
    }
    subscribeToVehicle((error, data) => {
      if (error) return;
      if (data) {
        setPosition({ lat: data?.latitude, lng: data?.longitude });
      }
    });
    return () => {
      if (vehicleId) disconnectSocket(vehicleId);
    };
  }, [vehicleId]);

  if (!vehicleId || !position || !position?.lat || !position?.lng) {
    return null;
  }

  return (
    <ReactLeafletDriftMarker position={position} duration={5000} keepAtCenter={false} icon={vehicleIcon}>
      {/* <Tooltip>{this.state.reverseGeo}</Tooltip> */}
    </ReactLeafletDriftMarker>
  );
};

export default DriftMarker;
