import api from './api';

const transport = {
  getTransports: function () {
    return api.get(`/transport`);
  },
  getTransportEventReport: async function (values) {
    try {
      const { data } = await api.get(`/transport/report`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default transport;
