import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navCollapsed: false,
  sideNavTheme: 'SIDE_NAV_LIGHT',
  locale: 'es',
  navType: 'SIDE',
  topNavColor: '#ffffff',
  headerNavColor: '#dfa826',
  mobileNav: false,
  currentTheme: 'light',
  direction: 'ltr',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleCollapsedNav: (state, action) => {
      return {
        ...state,
        navCollapsed: action.payload,
      };
    },
    sideNavStyleChange: (state, action) => {
      return {
        ...state,
        sideNavTheme: action.payload,
      };
    },
    changeLocale: (state, action) => {
      return {
        ...state,
        locale: action.payload,
      };
    },
    navTypeChange: (state, action) => {
      return {
        ...state,
        navType: action.payload,
      };
    },
    topNavColorChange: (state, action) => {
      return {
        ...state,
        topNavColor: action.payload,
      };
    },
    headerNavColorChange: (state, action) => {
      return {
        ...state,
        headerNavColor: action.payload,
      };
    },
    toggleMobileNav: (state, action) => {
      return {
        ...state,
        mobileNav: action.payload,
      };
    },
    switchTheme: (state, action) => {
      return {
        ...state,
        currentTheme: action.payload,
      };
    },
    directionChange: (state, action) => {
      return {
        ...state,
        direction: action.payload,
      };
    },
  },
});

const { reducer, actions } = themeSlice;
const themeReducer = reducer;

export const {
  toggleCollapsedNav,
  sideNavStyleChange,
  changeLocale,
  navTypeChange,
  topNavColorChange,
  headerNavColorChange,
  toggleMobileNav,
  switchTheme,
  directionChange,
} = actions;

export default themeReducer;
