import React, { useCallback } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { IntlProvider } from 'react-intl';
import keycloak from '../configs/keycloak';
import { getStorageItem, updateStorageItem } from 'utils/storageHelper';
import AppLocale from 'lang';
import { useSelector } from 'react-redux';
import { log } from 'utils/common';

const queryClient = new QueryClient();

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const Providers = ({ children }) => {
  const { locale, direction } = useSelector((state) => state.theme);
  const currentAppLocale = AppLocale[locale];

  const onKeycloakTokens = useCallback((tokens) => {
    log(tokens);
    if (!tokens.token) {
      getStorageItem('kcToken');
    } else {
      updateStorageItem('kcToken', JSON.stringify(tokens));
    }
  }, []);

  return (
    <ReactKeycloakProvider authClient={keycloak} onEvent={(event) => log(event)} onTokens={onKeycloakTokens}>
      <QueryClientProvider client={queryClient}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme="light" insertionPoint="styles-insertion-point">
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
              {children}
            </ConfigProvider>
          </IntlProvider>
        </ThemeSwitcherProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

export default Providers;
