import React from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import { route as apiRoute } from 'services';

class SaveRoute extends React.Component {
  helpDiv;

  addSpotsRoute = async (values, id) => {
    try {
      await apiRoute.addSpotsRoute(values, id);
      showSuccessToast('Se agregaron las paradas con éxito!');
    } catch (error) {
      showErrorToast('Algo salió mal. Por favor intenta de nuevo.');
    }
  };

  createButtonControl() {
    const MapHelp = L.Control.extend({
      onAdd: () => {
        const helpDiv = L.DomUtil.create('div', 'leaflet-bar');
        this.helpDiv = helpDiv;
        helpDiv.innerHTML = `<a href="#" role="button"><img src="/img/map/save.png" style="max-width: 18px" /></a>`;

        helpDiv.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          let aux = JSON.parse(JSON.stringify(this.props.spots));

          this.props.routingMachineRef.current.changes.map((x, i) => {
            if (x.latLng != null) {
              aux[i].latLng = x.latLng;
            }
            return x;
          });

          this.addSpotsRoute(JSON.parse(JSON.stringify(aux)), this.props.routeId);
        });

        return helpDiv;
      },
    });
    return new MapHelp({ position: 'topleft' });
  }

  componentDidMount() {
    const { map } = this.props;
    const control = this.createButtonControl();
    control.addTo(map);
  }

  componentWillUnmount() {
    this.helpDiv.remove();
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(SaveRoute);
