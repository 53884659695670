import jwt_decode from 'jwt-decode';
import { getStorageItem } from './storageHelper';

export function decodeKcToken(token = false) {
  if (!token) {
    const kcTokens = getStorageItem('kcToken');

    if (!kcTokens) {
      return undefined;
    }

    token = JSON.parse(kcTokens).idToken;
  }

  const { name, preferred_username, given_name, family_name, email, roles } = jwt_decode(token);

  return {
    name,
    preferred_username,
    given_name,
    family_name,
    email,
    roles,
  };
}
