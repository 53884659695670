import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, Navigate } from 'react-router-dom';
import { Loading } from 'components/shared-components';
import { decodeKcToken } from 'utils/tokenHelper';

const Login = () => {
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();
  const currentLocationState = location.state || {
    from: { pathname: '/home' },
  };

  useEffect(() => {
    if (initialized && !keycloak?.authenticated) {
      keycloak?.login();
    }
  }, [initialized, keycloak?.authenticated]);

  if (keycloak?.authenticated) {
    const kcToken = decodeKcToken(keycloak?.token);

    if (kcToken?.roles.includes('realm_admin')) {
      return <Navigate to={currentLocationState?.from} />;
    }
    keycloak?.logout();
  }

  return <Loading cover="page" />;
};

export default Login;
