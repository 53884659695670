import api from './api';

const message = {
  getMessages: async function (values) {
    try {
      const { data } = await api.get(`/message`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMessage: async function (id) {
    try {
      const { data } = await api.get(`/message/${id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createMessage: function (values) {
    return api.post(`/message`, values);
  },
  updateMessage: function (values) {
    const { id, ...restValues } = values;
    return api.put(`/message/${id}`, restValues);
  },
  removeMessage: function (id) {
    return api.delete(`/message/${id}`);
  },
};

export default message;
