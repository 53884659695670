import api from './api';

const turn = {
  getturns: async function (values) {
    try {
      const { data } = await api.get(`/turn`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getturn: async function (id) {
    try {
      const { data } = await api.get(`/turn/${id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createturn: function (values) {
    return api.post(`/turn`, values);
  },
  updateturn: function (values) {
    const { id, ...restValues } = values;
    return api.put(`/turn/${id}`, restValues);
  },
  removeturn: function (id) {
    return api.delete(`/turn/${id}`);
  },
};

export default turn;
