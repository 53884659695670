import React, { useState } from 'react';
import L from 'leaflet';
import { Marker as MBMarker, Popup, useMapEvents } from 'react-leaflet';
import { Button } from 'antd';
import { EnvironmentOutlined, PushpinOutlined } from '@ant-design/icons';

const Marker = ({ waypoints, onAddWaypoint, onRoutingMachineRef }) => {
  const [selectedPosition, setSelectedPosition] = useState(undefined);

  useMapEvents({
    click(e) {
      setSelectedPosition(e.latlng);
    },
  });

  /* const makeId = (length) => {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }; */

  const addMarkerToRoute = (position, active) => {
    const copyWaypoints = [...waypoints];

    onRoutingMachineRef(copyWaypoints);

    let waypoint;

    /* let hash = makeId(20); */

    if (active) {
      waypoint = {
        id: null,
        pickup: null,
        name: null,
        schedule: [],
        latLng: L.latLng(position.lat, position.lng),
        active,
      };
    } else {
      waypoint = {
        id: null,
        latLng: L.latLng(position.lat, position.lng),
        active,
      };
    }

    const saveWaypoints = [...copyWaypoints, waypoint];
    onAddWaypoint(saveWaypoints);
    setSelectedPosition(undefined);
  };

  return (
    <>
      {selectedPosition && (
        <MBMarker position={selectedPosition} interactive={true}>
          <Popup>
            <Button
              className="btn-rounded m-2"
              type="primary"
              onClick={() => addMarkerToRoute(selectedPosition, true)}
              icon={<EnvironmentOutlined />}
              size="middle"
            />
            <Button
              className="btn-rounded"
              type="primary"
              onClick={() => addMarkerToRoute(selectedPosition, false)}
              icon={<PushpinOutlined />}
              size="middle"
            />
          </Popup>
        </MBMarker>
      )}
    </>
  );
};

export default Marker;
