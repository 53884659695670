import axios from 'axios';
import { getStorageItem } from 'utils/storageHelper';
import { log } from 'utils/common';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = false;

instance.interceptors.request.use(
  (config) => {
    const tokens = getStorageItem('kcToken');
    if (tokens) {
      const token = JSON.parse(tokens).token;
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    log(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error &&
      ((error.response && (error.response.status === 401 || error.response.status === 403)) ||
        error.message === 'Network Error') &&
      window.localStorage.getItem('loggedIn')
    ) {
    }
    return Promise.reject(error);
  }
);

export default instance;
