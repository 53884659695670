import api from './api';

const company = {
  getNotifications: async function (values) {
    try {
      const { data } = await api.get(`/notification`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createNotification: function (values) {
    return api.post(`/notification`, values);
  },
};

export default company;
