import { log } from './common';
export function updateStorageItem(key, value) {
  try {
    localStorage.setItem(`${key}`, value);
  } catch (error) {
    log(`localstorage error during ${key} store:`, error);
  }
}

export function getStorageItem(key) {
  try {
    return localStorage.getItem(`${key}`);
  } catch (error) {
    log(`localstorage error during get ${key}:`, error);
  }
}

export function clearStorageItem(key) {
  try {
    localStorage.removeItem(`${key}`);
  } catch (error) {
    log(`localstorage error during remove ${key}:`, error);
  }
}

export function clearStorage() {
  localStorage.clear();
}
