import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import {
  /* EditOutlined, SettingOutlined, ShopOutlined, QuestionCircleOutlined, */ LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { decodeKcToken } from 'utils/tokenHelper';
/* import Icon from 'components/util-components/Icon'; */

/* const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/',
  },

  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/',
  },
]; */

export const NavProfile = () => {
  const { keycloak } = useKeycloak();
  const user = decodeKcToken(keycloak?.token);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} /* src={profileImg} */ />
          <div className="pl-3">
            <h4 className="mb-0">{user?.preferred_username}</h4>
            <span className="text-muted">{user?.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={0} /* key={menuItem.length + 1} */ onClick={() => keycloak?.logout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <a href="#/" onClick={(e) => e.preventDefault()}>
            <UserOutlined className="nav-icon mr-0" />
          </a>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
