import api from './api';

const appUse = {
  getAppUses: async function (values) {
    try {
      const { data } = await api.get(`/app-use`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAppUseReport: async function (values) {
    try {
      const { data } = await api.get(`/app-use/report`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default appUse;
