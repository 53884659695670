import api from './api';

const help = {
  getHelps: async function (values) {
    try {
      const { data } = await api.get(`/help-center`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getHelpReport: async function (values) {
    try {
      const { data } = await api.get(`/help-center/report`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default help;
