import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';

const spotIcon = L.icon({
  iconUrl: '/img/map/spot.png',
  iconSize: [48, 48],
  iconAnchor: [24, 48],
  popupAnchor: [0, -44],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

const pointIcon = L.icon({
  iconUrl: '/img/map/point.png',
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

const CreateRoutineMachineLayer = ({ waypoints, onRemoveWaypoint, onShowForm, mapApiKey }) => {
  const myPopup = L.DomUtil.create('div', 'infoWindow');
  myPopup.innerHTML = '<button>Eliminar</button>';

  const button = myPopup.childNodes[0];
  button.addEventListener('click', () => {
    onRemoveWaypoint(button.id);
  });

  const instance = L.Routing.control({
    router: L.Routing.mapbox(mapApiKey),
    waypoints: waypoints.length
      ? waypoints.map((wp) => {
          return wp.latLng;
        })
      : [],
    lineOptions: {
      styles: [{ color: '#6FA1EC', weight: 4 }],
      addWaypoints: false,
    },
    createMarker: function (i, wp) {
      const waypoints = instance.getWaypoints();
      const types = waypoints.length
        ? waypoints.map((wp) => {
            return wp.active;
          })
        : [];
      if (types[i]) {
        return L.marker(wp.latLng, { draggable: true, icon: spotIcon }).on('click', () => onShowForm(i));
      } else {
        return L.marker(wp.latLng, { draggable: true, icon: pointIcon }).on('click', () => onShowForm(i));
      }
    },
    draw: function () {
      instance.route();
    },
    autoRoute: true,
    show: false,
    routeWhileDragging: false,
    draggableWaypoints: true,
    fitSelectedRoutes: false,
    showAlternatives: false,
  }).on('waypointschanged', (e) => {
    instance.changes = e.waypoints;
  });

  instance.changes = [];

  return instance;
};

const RoutingMachine = createControlComponent(CreateRoutineMachineLayer);

export default RoutingMachine;
