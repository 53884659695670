import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  console.log('keycloak auth', keycloak?.authenticated);
  return keycloak?.authenticated ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
