import api from './api';

const user = {
  getUsers: async function (values) {
    try {
      const { data } = await api.get(`/user`, { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getUser: async function (id) {
    try {
      const { data } = await api.get(`/user/${id}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createUser: function (values) {
    return api.post(`/user`, values);
  },
  updateUser: function (values) {
    const { id, ...restValues } = values;
    return api.put(`/user/${id}`, restValues);
  },
  removeUser: function (id) {
    return api.delete(`/user/${id}`);
  },
  changeStatusUser: function (id) {
    return api.put(`/user/${id}/status`);
  },
  getRoles: async function () {
    try {
      const { data } = await api.get(`/user/all/roles`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default user;
