import api from './api';

const route = {
  getRoutes: async function (values) {
    try{
      const {data} = await api.get(`/route`,  { params: values });
      return data
    }catch(error){
    return Promise.reject(error)}
  },
  getRoute:async function (id) {
    try{
      const {data} = await api.get(`/route/${id}`);
      return data
    }catch(error){
      return Promise.reject(error)
    }
  },
  createRoute: function (values) {
    return api.post(`/route`, values);
  },
  updateRoute: function (values) {
    const {id, ...restValues} = values
    return api.put(`/route/${id}`, restValues);
  },
  addSpotsRoute: function(values,id) {
    return api.put(`/route/${id}/add-spots`,values);
  },
  changeStatusRoute: function (id) {
    return api.put(`/route/${id}/status`);
  },
  removeSpot: function (id) {
    return api.delete(`/spot/${id}`);
  },
  removeRoute: function (id) {
    return api.delete(`/route/${id}`);
  }
}; 

export default route;
