import antdEsEs from 'antd/es/locale/es_ES';
import esMsg from '../locales/es_ES.json';

const EsLang = {
  antd: antdEsEs,
  locale: 'es-Es',
  messages: {
    ...esMsg,
  },
};
export default EsLang;
