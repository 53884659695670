import React, { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Loading from '../../shared-components/Loading';

const PrivateOutlet = () => {
  const location = useLocation();
  const { keycloak } = useKeycloak();

  return keycloak?.authenticated ? (
    <Suspense fallback={<Loading cover="page" />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateOutlet;
