import api from './api';

const company = {
  getCompanies: async function (values = null) {
    try {
      const { data } = await api.get(`/company`, values && { params: values });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCompany: async function (id) {
    try {
      const { data } = await api.get(`/company/${id}`);
      return data;
    } catch (error) {
      Promise.reject(error);
    }
  },
  createCompany: function (values) {
    return api.post(`/company`, values);
  },
  updateCompany: function (values) {
    const { id, ...restValues } = values;
    return api.put(`/company/${id}`, restValues);
  },
  changeStatusCompany: function (id) {
    return api.put(`/company/${id}/status`);
  },
  removeCompany: function (id) {
    return api.delete(`/company/${id}`);
  },
};

export default company;
