import React, { useEffect, useState } from 'react';
import { Button, Form, Input, TimePicker, Row, Col } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';
import { DATE_FORMAT_HH_mm } from 'constants/DateConstant';
import { route as routeService } from 'services';
import { showErrorToast } from 'utils/showToast';

const SpotForm = ({ waypoints, waypointIndex, onAddWaypoint, onRemoveWaypoint, id }) => {
  const [active, setActive] = useState(false);
  const [form] = Form.useForm();

  const { isLoading: routeIsLoading } = useQuery(
    ['route', id],
    () => {
      return routeService.getRoute(id);
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      notifyOnChangePropsExclusions: ['isStale'],
      onError: () => {
        showErrorToast('Error obteniendo la Ruta. Por favor recargue la página.');
      },
      onSuccess: (values) => {
        let schedule = values.turns.map((turn) => ({ time: '', turn: turn.name }));
        if (waypoints.length && waypoints[waypointIndex]?.active) {
          const waypoint = waypoints[waypointIndex];
          const scheduleLength = waypoint.schedule.length;
          if (scheduleLength) {
            const copySchedule = [...waypoint.schedule];
            const selectedSchedule = copySchedule
              .filter((fs) => schedule.some((s) => s.turn === fs.turn))
              .map((ms) => ({ ...ms, time: moment(ms.time) }))
            schedule = selectedSchedule;
          }
        }
        form.setFieldsValue({
          schedule,
        });
      },
    }
  );

  useEffect(() => {
    if (waypoints.length && waypoints[waypointIndex]?.active) {
      form.setFieldsValue({
        pickup: waypoints[waypointIndex]?.pickup,
        name: waypoints[waypointIndex]?.name,
      });
    }
    setActive(waypoints[waypointIndex]?.active);

    return () => {
      form.resetFields();
    };
  }, [waypointIndex]);

  const onFinish = (values) => {
    const saveWaypoints = [...waypoints];
    const waypoint = saveWaypoints[waypointIndex];
    saveWaypoints[waypointIndex] = { ...waypoint, ...values };
    onAddWaypoint(saveWaypoints);
  };

  return (
    <div>
      <div className="form-title">
        <h3>{active ? 'PARADA' : 'PUNTO'}</h3>
      </div>
      <Form form={form} name="points" onFinish={onFinish} scrollToFirstError size="middle" layout="vertical">
        {active && (
          <>
            <Form.Item
              name="pickup"
              label="Recolección:"
              rules={[
                {
                  required: active,
                  message: 'Por favor ingresa una Recolección',
                },
              ]}
            >
              <Input placeholder="Recolección" disabled={!active} />
            </Form.Item>
            <Form.Item
              name="name"
              label="Nombre:"
              rules={[
                {
                  required: active,
                  message: 'Por favor ingresa un Nombre',
                },
              ]}
            >
              <Input placeholder="Nombre" disabled={!active} />
            </Form.Item>
            <Form.List name="schedule">
              {(fields) => (
                <>
                  {fields.map((field, idx) => (
                    <Row gutter={16} key={idx}>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'time']}
                          label="Horario"
                          fieldKey={[field.fieldKey, 'time']}
                          rules={[
                            {
                              required: active,
                              message: 'Por favor ingrese un horario',
                            },
                          ]}
                        >
                          <TimePicker
                            disabled={!active}
                            className="w-100"
                            format={DATE_FORMAT_HH_mm}
                            placeholder="Horario"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'turn']}
                          label="Turno"
                          fieldKey={[field.fieldKey, 'turn']}
                        >
                          <Input placeholder="Turno" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </>
        )}
        <div className="bottom-row">
          <div className="actions-row">
            <Button
              onClick={() => onRemoveWaypoint(waypointIndex)}
              type="secondary"
              className="btn-rounded"
              loading={false}
            >
              Eliminar
            </Button>
            <Button disabled={!active} type="primary" htmlType="submit" className="btn-rounded" loading={false}>
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SpotForm;
